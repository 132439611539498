@import "../fonts.scss";

.Home {
  height: calc(100vh - 196px);
  position: relative;

  &__Text {
    font-family: $alteBold;
    font-size: 48px;
    line-height: 52px;
    color: white;
    width: 100%;
    max-width: 700px;
    text-align: center;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
  }

  &__Video {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
  }

  &__Video--Wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

@media (max-width: 765px) {
  .Home {
    height: calc(100vh - 246px);

    &__Text {
      font-size: 35px;
      line-height: 38px;
      max-width: 450px;
      padding: 0 15px;
    }
  }
}
