@import url(//hello.myfonts.net/count/3b8fd7);
@font-face{font-family:"alte-bold";src:url(/static/media/AlteHaasGroteskBold.755651c8.woff) format("woff")}@font-face{font-family:"alte-regular";src:url(/static/media/AlteHaasGroteskRegular.a9052b89.woff) format("woff")}body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.Project{margin:auto;max-width:1020px;margin-top:80px;padding:0 20px;box-sizing:border-box}.Project__Title{font-family:"alte-bold","Helvetica",Times,serif;color:white;margin:auto;width:100%;margin-bottom:15px}.Project__Video{width:100%;padding-bottom:56.25%;position:relative}.Project__Video iframe{width:100%;height:100%;position:absolute;top:0;left:0}.Project__Credits{display:flex;color:white;margin:auto;margin-top:40px;max-width:800px}.Project__Credits__Column{flex:1 1;box-sizing:border-box}.Project__Credits__Label{text-align:right;padding-right:40px;font-family:"alte-regular","Helvetica",Arial,sans-serif;width:40%;flex:none}.Project__Credits__List{font-family:"Times New Roman",Times,serif}.Project__Credits__List ul{list-style:none;padding:0;margin:0}@media (max-width: 765px){.Project{padding:0 20px;width:100%;box-sizing:border-box}.Project__Credits{display:block}.Project__Credits__Label{text-align:left;margin-bottom:6px}}

.Work__Button{position:fixed;top:50%;font-family:"alte-regular","Helvetica",Arial,sans-serif;color:white;cursor:pointer;transform:translateY(-50%)}.Work__Next{right:0;padding:50px 20px 50px 50px}.Work__Prev{left:0;padding:50px 50px 50px 20px}

.Copyright{font-family:"Times New Roman", Times, serif;color:white;padding:10px 20px;margin:auto;border:0.5px solid white;text-transform:uppercase;margin-bottom:30px;margin-top:30px;font-size:12px;text-align:center;white-space:nowrap}

.Contact{display:flex;color:white;font-size:14px;line-height:18px}.Contact__Column{flex:0 0 50%;box-sizing:border-box}.Contact__Label{font-family:"alte-regular","Helvetica",Arial,sans-serif;text-align:right;padding-right:40px}.Contact__Info ul{list-style:none;font-family:"Times New Roman",Times,serif;padding:0;margin:0}.Contact__Info ul li{font-style:normal}.Contact__Info ul li a{text-decoration:none;color:white}.Contact__Info ul li a:hover{text-decoration:underline}

.About{width:100%;max-width:880px;margin:auto;margin-top:150px}.About__Text{margin-bottom:100px}.About__Paragraph{color:white;font-family:"alte-bold","Helvetica",Times,serif;font-size:36px;line-height:39px;text-align:center}@media (max-width: 765px){.About__Paragraph{font-size:26px;line-height:29px;padding:0 20px}}

.Feed{margin-top:50px}.Feed__Image{margin:auto;width:100%;max-width:1020px;margin-bottom:20px;padding:0 5px}.Feed__Image img{max-width:100%;max-height:800px;margin:auto;display:block}.Feed__Image video{margin:auto;max-width:100%;display:block}

.Home{height:calc(100vh - 196px);position:relative}.Home__Text{font-family:"alte-bold","Helvetica",Times,serif;font-size:48px;line-height:52px;color:white;width:100%;max-width:700px;text-align:center;position:absolute;bottom:100px;left:50%;transform:translateX(-50%);box-sizing:border-box}.Home__Video{pointer-events:none;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);height:100%;width:177.77777778vh;min-width:100%;min-height:56.25vw}.Home__Video--Wrap{position:fixed;width:100%;height:100%;top:0;left:0;z-index:-1}@media (max-width: 765px){.Home{height:calc(100vh - 246px)}.Home__Text{font-size:35px;line-height:38px;max-width:450px;padding:0 15px}}

.Logo{transition:fill 0.5s ease;position:absolute;top:30px;left:30px;width:160px;height:50px;background-repeat:no-repeat;background-size:contain}

.Nav{top:0;left:0;width:100%;height:100px;background-color:black}.Nav--Home{background-color:transparent}.Nav__Logo{height:30px;position:absolute;top:40px;left:100px;transition:fill 0.4s ease}.Nav__Links{display:flex;position:absolute;right:100px;top:40px;height:30px;list-style:none;font-family:"alte-regular","Helvetica",Arial,sans-serif;width:100%;max-width:400px;margin:0}.Nav__Links__Link{text-transform:uppercase;flex:1 1;align-items:center}.Nav__Links__Link a{line-height:30px;vertical-align:middle;color:#f2f2f2;text-decoration:none;position:relative}.Nav__Links__Link a:after{content:"";width:4px;height:4px;background-color:white;position:absolute;left:50%;margin-left:-2px;bottom:-4px;opacity:0;transition:all 0.15s ease}.Nav__Links__Link a.active:after{opacity:1;bottom:-20px}.Nav__Links__Link a.active:hover:after{opacity:1}.Nav__Links__Link a:hover{color:white}.Nav__Links__Link a:hover:after{opacity:0.5;bottom:-20px}@media (max-width: 765px){.Nav{height:150px}.Nav__Logo{left:40px}.Nav__Links{left:40px;top:100px;padding-left:0;width:auto}}

*{box-sizing:border-box}body,html{margin:0;padding:0}body{background-color:black}html{min-height:100%}.App{display:flex;flex-direction:column;min-height:100vh}.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}

