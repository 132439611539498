@import "../fonts.scss";

.Copyright {
  font-family: "Times New Roman", Times, serif;
  color: white;
  padding: 10px 20px;
  margin: auto;
  border: 0.5px solid white;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}
