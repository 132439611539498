@import "../fonts.scss";

.About {
  width: 100%;
  max-width: 880px;
  margin: auto;
  margin-top: 150px;

  &__Text {
    margin-bottom: 100px;
  }

  &__Paragraph {
    color: white;
    font-family: $alteBold;
    font-size: 36px;
    line-height: 39px;
    text-align: center;
  }
}

@media (max-width: 765px) {
  .About {
    &__Paragraph {
      font-size: 26px;
      line-height: 29px;
      padding: 0 20px;
    }
  }
}
