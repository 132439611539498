@import "../fonts.scss";

.Contact {
  display: flex;
  color: white;
  font-size: 14px;
  line-height: 18px;

  &__Column {
    flex: 0 0 50%;
    box-sizing: border-box;
  }

  &__Label {
    font-family: $alteRegular;
    text-align: right;
    padding-right: 40px;
  }

  &__Info {
    ul {
      list-style: none;
      font-family: $times;
      padding: 0;
      margin: 0;

      li {
        font-style: normal;

        a {
          text-decoration: none;
          color: white;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
