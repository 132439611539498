@import "../fonts.scss";

.Logo {
  transition: fill 0.5s ease;
  position: absolute;
  top: 30px;
  left: 30px;
  width: 160px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
}
