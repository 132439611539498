@import "../fonts.scss";

.Project {
  margin: auto;
  max-width: 1020px;
  margin-top: 80px;
  padding: 0 20px;
  box-sizing: border-box;

  &__Title {
    font-family: $alteBold;
    color: white;
    margin: auto;
    width: 100%;
    margin-bottom: 15px;
  }

  &__Video {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__Credits {
    display: flex;
    color: white;
    margin: auto;
    margin-top: 40px;
    max-width: 800px;

    &__Column {
      flex: 1;
      box-sizing: border-box;
    }

    &__Label {
      text-align: right;
      padding-right: 40px;
      font-family: $alteRegular;
      width: 40%;
      flex: none;
    }

    &__List {
      font-family: $times;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}

@media (max-width: 765px) {
  .Project {
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;

    &__Credits {
      display: block;

      &__Label {
        text-align: left;
        margin-bottom: 6px;
      }
    }
  }
}
