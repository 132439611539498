@import "../fonts.scss";

.Nav {
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: black;

  &--Home {
    background-color: transparent;
  }

  &__Logo {
    height: 30px;
    position: absolute;
    top: 40px;
    left: 100px;
    transition: fill 0.4s ease;
  }

  &__Links {
    display: flex;
    position: absolute;
    right: 100px;
    top: 40px;
    height: 30px;
    list-style: none;
    font-family: $alteRegular;
    width: 100%;
    max-width: 400px;
    margin: 0;

    &__Link {
      text-transform: uppercase;
      flex: 1;
      align-items: center;

      a {
        line-height: 30px;
        vertical-align: middle;
        color: #f2f2f2;
        text-decoration: none;
        position: relative;

        &:after {
          content: "";
          width: 4px;
          height: 4px;
          background-color: white;
          position: absolute;
          left: 50%;
          margin-left: -2px;
          bottom: -4px;
          opacity: 0;
          transition: all 0.15s ease;
        }

        &.active {
          &:after {
            opacity: 1;
            bottom: -20px;
          }

          &:hover:after {
            opacity: 1;
          }
        }

        &:hover {
          color: white;

          &:after {
            opacity: 0.5;
            bottom: -20px;
          }
        }
      }
    }
  }
}

@media (max-width: 765px) {
  .Nav {
    height: 150px;

    &__Logo {
      left: 40px;
    }

    &__Links {
      left: 40px;
      top: 100px;
      padding-left: 0;
      width: auto;
    }
  }
}
