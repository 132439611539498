.Feed {
  margin-top: 50px;

  &__Image {
    margin: auto;
    width: 100%;
    max-width: 1020px;
    margin-bottom: 20px;
    padding: 0 5px;

    img {
      max-width: 100%;
      max-height: 800px;
      margin: auto;
      display: block;
    }

    video {
      margin: auto;
      max-width: 100%;
      display: block;
    }
  }
}
