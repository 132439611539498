@import "../fonts.scss";

.Work {
  &__Button {
    position: fixed;
    top: 50%;
    font-family: $alteRegular;
    color: white;
    cursor: pointer;
    transform: translateY(-50%);
  }

  &__Next {
    right: 0;
    padding: 50px 20px 50px 50px;
  }

  &__Prev {
    left: 0;
    padding: 50px 50px 50px 20px;
  }
}
